import { Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import LevelOne from './pages/LevelOne';
import LevelTwo from './pages/LevelTwo';

const Switch = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/level-1" element={<LevelOne />} />
      <Route exact path="/level-2" element={<LevelTwo />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};

export default Switch;
