import { PauseIcon, PlayIcon } from '@heroicons/react/24/solid';
import FocusTrap from 'focus-trap-react';
import { useEffect, useRef, useState } from 'react';
import channel10 from '../assets/gif/channel-10.gif';
import channel12 from '../assets/gif/channel-12.gif';
import channel5 from '../assets/gif/channel-5.gif';
import channel6 from '../assets/gif/channel-6.gif';
import channel7 from '../assets/gif/channel-7.gif';
import channel8 from '../assets/gif/channel-8.gif';
import pinkStatic from '../assets/gif/pink-static.gif';
import secretChannel1 from '../assets/gif/secret-channel-1.gif';
import secretChannel2 from '../assets/gif/secret-channel-2.gif';
import channel3 from '../assets/png/channel-3.png';
import channelKnob from '../assets/png/channel-knob.png';
import knob from '../assets/png/knob.png';
import tvLayout from '../assets/png/tv-layout.png';
import Button from '../Button';
import useEscKey from '../hooks/useEscKey';

function LevelOne() {
  const audioRef = useRef(null);

  const [channelAsset, setChannelAsset] = useState(pinkStatic);
  const [channelRotation, setChannelRotation] = useState(0);
  const [isAudioPlaying, setIsAudioPlaying] = useState(true);

  const [brightnessRotation, setBrightnessRotation] = useState(0);
  const [contrastRotation, setContrastRotation] = useState(0);
  const [volumeRotation, setVolumeRotation] = useState(0);

  const [channel, setChannel] = useState(1);
  const [volume, setVolume] = useState(1);
  const [contrast, setContrast] = useState(1);
  const [brightness, setBrightness] = useState(1);

  const handleButtonClick = () => {
    setChannel((prevCounter) => (prevCounter < 13 ? prevCounter + 1 : 1));
  };

  const handleVolumeClick = () => {
    setVolume((prevCounter) => (prevCounter < 10 ? prevCounter + 1 : 1));
  };

  const handleContastClick = () => {
    setContrast((prevCounter) => (prevCounter < 10 ? prevCounter + 1 : 1));
  };

  const handleBrightnessClick = () => {
    setBrightness((prevCounter) => (prevCounter < 10 ? prevCounter + 1 : 1));
  };

  const url = `${window.location.origin}/mp3/𐁔.mp3`;
  const handleDownload = () => {
    const userConfirmed = window.confirm(
      'would you like to download: 𐁔 (1/6)?'
    );
    if (userConfirmed) {
      const link = document.createElement('a');
      link.href = url;
      link.download = '𐁔.mp3';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    }
  };

  const url2 = `${window.location.origin}/pdf/class-of-cardinal-sin-script.pdf`;
  const handleScriptDownload = () => {
    const userConfirmed = window.confirm(
      'would you like to download "Class Of Cardinal Sin - Pilot"'
    );
    if (userConfirmed) {
      const link = document.createElement('a');
      link.href = url2;
      link.download = 'class-of-cardinal-sin-script.pdf';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    setChannelAsset(pinkStatic);

    if (channel === 3) {
      setChannelAsset(channel3);
    }
    if (channel === 5) {
      setChannelAsset(channel5);
    }
    if (channel === 6) {
      setChannelAsset(channel6);
    }
    if (channel === 7) {
      setChannelAsset(channel7);
    }
    if (channel === 8) {
      setChannelAsset(channel8);
    }
    if (channel === 10) {
      setChannelAsset(channel10);
    }
    if (channel === 12) {
      setChannelAsset(channel12);
    }

    if (channel === 1 && volume === 5 && contrast === 5 && brightness === 9) {
      setChannelAsset(secretChannel1);
      setTimeout(() => {
        handleDownload();
      }, 5000);
    }
  }, [channel, volume, contrast, brightness]);

  const toggleAudio = () => {
    setIsAudioPlaying(!isAudioPlaying);

    return audioRef.current.paused
      ? audioRef.current.play()
      : audioRef.current.pause();
  };

  const channelClickHandler = () => {
    setChannelRotation(channelRotation + 27.692307);
    handleButtonClick();
  };

  const volumeClickHandler = () => {
    setVolumeRotation(volumeRotation + 36);
    handleVolumeClick();
  };

  const brightClickHandler = () => {
    setBrightnessRotation(brightnessRotation + 36);
    handleContastClick();
  };

  const contrastClickHandler = () => {
    setContrastRotation(contrastRotation + 36);
    handleBrightnessClick();
  };

  const [isOpen, setIsOpen] = useState(false);
  const [password, setPassword] = useState('');

  const handleSubmit = () => {
    const pw = 'diagnosticearlydiscovery';
    if (password === pw) {
      setIsOpen(false);
      setChannelAsset(secretChannel2);
      setTimeout(() => {
        handleScriptDownload();
      }, 5000);
    } else {
      alert('incorrect password');
    }
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  useEscKey(() => {
    setIsOpen(false);
  });

  return (
    <div className="bg-ink min-h-screen flex items-center justify-center overflow-hidden">
      <button
        className="absolute bottom-2 right-2 z-50 bg-reverse p-2"
        onClick={toggleAudio}
      >
        {isAudioPlaying ? (
          <PauseIcon className="size-4" />
        ) : (
          <PlayIcon className="size-4" />
        )}
      </button>
      <audio
        ref={audioRef}
        autoPlay
        loop
        src={`${window.location.origin}/mp3/website-vibes.mp3`}
      />

      <div className="relative w-full max-w-[1440px] max-h-screen">
        <div className="relative pt-[59%] max-h-screen">
          <img
            src={tvLayout}
            className="z-20 top-0 absolute h-full max-h-screen"
            alt="An old timey television set."
          />
          <img
            src={channelAsset}
            className="h-[75%] absolute top-1/2 transform -translate-y-1/2 z-10 w-[60%] left-[7%] "
            alt=""
          />
        </div>
        <Button
          clickHandler={channelClickHandler}
          asset={channelKnob}
          rotation={channelRotation - 27.692307}
          className="z-30 absolute top-[23%] right-[4.3%] h-[15%] w-[11%]"
          imageClassName="w-full h-full object-contain"
          alt="Channel knob"
        />

        <div className="z-30 absolute top-[12.5%] right-[20%] flex-col flex w-[4%] h-[29%] justify-between">
          <Button
            clickHandler={volumeClickHandler}
            asset={knob}
            rotation={volumeRotation}
            imageClassName="w-full h-full"
            alt="Volume knob"
          />
        </div>

        <div className="z-30 absolute top-[22.5%] right-[20.4%] w-[4%] h-[18.5%] flex flex-col justify-between">
          <Button
            clickHandler={brightClickHandler}
            asset={knob}
            rotation={brightnessRotation}
            imageClassName="w-full h-full"
          />
          <Button
            clickHandler={contrastClickHandler}
            asset={knob}
            rotation={contrastRotation}
            imageClassName="w-full h-full"
          />
        </div>

        <button
          className="absolute p-1 bg-transparent top-[48%] right-[13.5%] h-[4%] w-[] transform z-30 cursor-auto"
          onClick={() => setIsOpen(true)}
        ></button>
      </div>

      {isOpen && (
        <FocusTrap>
          <div className="fixed h-full w-full bg-ink bg-opacity-80 z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <label className="text-reverse font-lack">
                password required:
              </label>
              <input
                type="password"
                className="bg-reverse text-ink h-8 p-2"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="submit" className="bg-ink p-2 text-reverse">
                enter
              </button>
            </form>

            <button
              className="absolute font-lack top-8 right-8 text-reverse"
              onClick={closeDialog}
            >
              close
            </button>
          </div>
        </FocusTrap>
      )}

      <p className="absolute bottom-4 left-4 text-ink font-lack">
        website by
        <a href="https://www.youngandnauseo.us/" target="_blank">
          young and nauseous
        </a>
      </p>
    </div>
  );
}

export default LevelOne;
