const TRANSITION = 'transform 0.3s ease';

const Button = ({
  clickHandler,
  asset,
  rotation,
  className,
  imageClassName,
  alt,
  index,
  style,
}) => {
  return (
    <button onClick={clickHandler} className={className} style={style}>
      <img
        data-index={index}
        src={asset}
        className={imageClassName}
        alt={alt}
        style={{
          transform: `rotate(${rotation}deg)`,
          transition: TRANSITION,
        }}
      />
    </button>
  );
};

export default Button;
