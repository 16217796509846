import { Link } from 'react-router-dom';
import levelOne from '../assets/png/level-1.png';
import levelTwo from '../assets/png/level-2.png';
import levelThree from '../assets/png/level-3.png';

function Home() {
  return (
    <div className="bg-ink min-h-screen flex items-center justify-center overflow-hidden p-4 gap-4 flex-col md:flex-row">
      <Link to="/level-1">
        {' '}
        <img src={levelOne} alt="Level one." />
      </Link>
      <Link to="/level-2" className="">
        {' '}
        <img src={levelTwo} alt="Level two." />
      </Link>
      <Link to="/" className="disabled cursor-not-allowed opacity-50">
        {' '}
        <img src={levelThree} alt="Level three." />
      </Link>
    </div>
  );
}

export default Home;
