import { BrowserRouter as Router } from 'react-router-dom';
import Switch from './Switch';

import './styles/app.css';

function App() {
  console.log('website by young and nauseous - youngandnauseo.us');
  return (
    <Router>
      <Switch />
    </Router>
  );
}

export default App;
