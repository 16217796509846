import { useEffect } from 'react';

function useEscKey(callback) {
  useEffect(() => {
    // Function to handle the escape key press
    const handleEscKey = (event) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        callback();
      }
    };

    // Add event listener for keydown
    document.addEventListener('keydown', handleEscKey);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [callback]); // Dependency array to ensure the callback is up-to-date
}

export default useEscKey;
