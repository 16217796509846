/* eslint-disable no-unused-expressions */
/* eslint-disable no-dupe-else-if */
/* eslint-disable no-plusplus */

import { PauseIcon, PlayIcon } from '@heroicons/react/24/solid';
import FocusTrap from 'focus-trap-react';
import { useEffect, useRef, useState } from 'react';
import Button from '../Button';
import channel10 from '../assets/level-2/gif/level-2-channel-10.gif';
import channel2 from '../assets/level-2/gif/level-2-channel-2.gif';
import channel3 from '../assets/level-2/gif/level-2-channel-3.gif';
import channel5 from '../assets/level-2/gif/level-2-channel-5.gif';
import channel6 from '../assets/level-2/gif/level-2-channel-6.gif';
import channel8 from '../assets/level-2/gif/level-2-channel-8.gif';
import channel9 from '../assets/level-2/gif/level-2-channel-9.gif';
import secretChannel2 from '../assets/level-2/gif/level-2-safe-closed.gif';
import secretChannel3 from '../assets/level-2/gif/level-2-safe-open.gif';
import greenStatic from '../assets/level-2/gif/level-2-static.gif';
import secretChannel1 from '../assets/level-2/gif/slider-one-three-channel.gif';
import channelKnob from '../assets/level-2/png/level-2-channel.png';
import slider from '../assets/level-2/png/level-2-slider.png';
import topKnob from '../assets/level-2/png/level-2-top-knob.png';
import tvLayout from '../assets/level-2/png/level-2-tv.png';
import useEscKey from '../hooks/useEscKey';

function LevelTwo() {
  const [channelAsset, setChannelAsset] = useState(greenStatic);
  const [channel, setChannel] = useState(1);
  const [channelRotation, setChannelRotation] = useState(0);
  const [topRotation, setTopRotation] = useState(0);
  const [topKnobStatus, setTopKnobStatus] = useState('off');
  const [sliderOneStatus, setSliderOneStatus] = useState('off');
  const [sliderTwoStatus, setSliderTwoStatus] = useState('off');
  const [sliderThreeStatus, setSliderThreeSatus] = useState('off');
  const [downloadButtonActive, setDownloadButtonActive] = useState(false);
  const [downloadButtonTwoActive, setDownloadButtonTwoActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [password, setPassword] = useState('');

  const url2 = `${window.location.origin}/pdf/the-middle-ground-act-1.pdf`;
  const url3 = `${window.location.origin}/mp3/cassette-2.mp3`;

  const handleScriptDownload = () => {
    const userConfirmed = window.confirm(
      'would you like to download "The Middle Ground: Act 1"?'
    );
    if (userConfirmed) {
      const link = document.createElement('a');
      link.href = url2;
      link.download = 'the-middle-ground-act-1.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => {
        const link2 = document.createElement('a');
        link2.href = url3;
        link2.download = 'cassette-2.mp3';
        document.body.appendChild(link2);
        link2.click();
        document.body.removeChild(link2);
      }, 1000);
    }
  };

  const handleSubmit = () => {
    const pw = 'killtheelders';
    if (password === pw) {
      setIsOpen(false);
      setChannelAsset(secretChannel3);
      setTimeout(() => {
        handleScriptDownload();
      }, 5000);
    } else {
      alert('incorrect password');
    }
  };

  const [keyPresses, setKeyPresses] = useState([]);

  const handleButtonClick = () => {
    setChannel((prevCounter) => (prevCounter < 13 ? prevCounter + 1 : 1));
  };

  const channelClickHandler = () => {
    setChannelRotation(channelRotation + 27.692307);
    handleButtonClick();
  };

  const sliderClickHandler = (e) => {
    if (e.target.dataset.index === '1') {
      if (sliderOneStatus === 'off') {
        setSliderOneStatus('on');
      } else {
        setSliderOneStatus('off');
      }
    } else if (e.target.dataset.index === '2') {
      if (sliderTwoStatus === 'off') {
        setSliderTwoStatus('on');
      } else {
        setSliderTwoStatus('off');
      }
    } else if (e.target.dataset.index === '3') {
      if (sliderThreeStatus === 'off') {
        setSliderThreeSatus('on');
      } else {
        setSliderThreeSatus('off');
      }
    }
  };

  const topKnobClickHandler = () => {
    if (topKnobStatus === 'off') {
      setTopKnobStatus('on');
      setTopRotation(0);
      setTopRotation(topRotation + 27.692307 + 27.692307);
    } else {
      setTopKnobStatus('off');
      setTopRotation(0);
      setTopRotation(topRotation - 27.692307 - 27.692307);
    }
  };

  useEffect(() => {
    setChannelAsset(greenStatic);

    if (channel === 2) {
      setChannelAsset(channel2);
    }

    if (channel === 3 && topKnobStatus === 'on') {
      setChannelAsset(channel3);
    }
    if (channel === 5) {
      setChannelAsset(channel5);
    }
    if (channel === 6) {
      setChannelAsset(channel6);
    }
    if (channel === 8) {
      setChannelAsset(channel8);
    }
    if (channel === 9) {
      setChannelAsset(channel9);
    }
    if (channel === 10) {
      setChannelAsset(channel10);
    }

    if (
      sliderOneStatus === 'on' &&
      sliderTwoStatus === 'off' &&
      sliderThreeStatus === 'on' &&
      topKnobStatus === 'on'
    ) {
      setChannelAsset(secretChannel1);
      setDownloadButtonActive(true);
    }

    if (keyPresses.length === 4) {
      checkKeyPresses();
    }
  }, [
    channel,
    topKnobStatus,
    sliderOneStatus,
    sliderThreeStatus,
    sliderTwoStatus,
    keyPresses,
  ]);

  const url = `${window.location.href}/mp3/𐀂.mp3`;

  const handleDownload = () => {
    console.log('download button clicked');
    const userConfirmed = window.confirm(
      'would you like to download: 𐀂 (2/6)?'
    );

    if (userConfirmed) {
      const link = document.createElement('a');
      link.href = url;
      link.download = '𐀂.mp3';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    }
  };

  function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  const checkKeyPresses = () => {
    if (arraysEqual(keyPresses, [1, 3, 12, 35])) {
      setChannelAsset(secretChannel2);
      setDownloadButtonTwoActive(true);
    }
  };

  const handleKeyPadClick = (e) => {
    console.log(e.target.dataset.key);
    const key = parseInt(e.target.dataset.key, 10);

    if (keyPresses.length === 4) {
      setKeyPresses([key]); // Reset the array and add the 5th key press
    } else {
      setKeyPresses((prevKeyPresses) => [...prevKeyPresses, key]);
    }
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  useEscKey(() => {
    setIsOpen(false);
  });

  const [isAudioPlaying, setIsAudioPlaying] = useState(true);

  const toggleAudio = () => {
    setIsAudioPlaying(!isAudioPlaying);

    return audioRef.current.paused
      ? audioRef.current.play()
      : audioRef.current.pause();
  };

  const audioRef = useRef(null);
  const grid = new Array(42).fill(null);

  return (
    <div className="bg-ink min-h-screen flex items-center justify-center overflow-hidden">
      <button
        className="absolute bottom-2 right-2 z-50 bg-reverse p-2"
        onClick={toggleAudio}
      >
        {isAudioPlaying ? (
          <PauseIcon className="size-4" />
        ) : (
          <PlayIcon className="size-4" />
        )}
      </button>
      <audio
        ref={audioRef}
        autoPlay
        loop
        src={`${window.location.origin}/mp3/website-vibes.mp3`}
      />
      <div className="relative w-full max-w-[1440px] max-h-screen">
        <div className="relative pt-[59%] max-h-screen">
          <img
            src={tvLayout}
            className="z-20 top-0 absolute h-full max-h-screen"
            alt="An old timey television set."
          />
          <img
            src={channelAsset}
            className="h-[75%] absolute top-1/2 transform -translate-y-1/2 z-10 w-[60%] left-[7%] "
            alt=""
          />

          {downloadButtonActive && (
            <button
              onClick={() => handleDownload()}
              className="h-[75%] absolute top-1/2 transform -translate-y-1/2  w-[60%] left-[7%] bg-tansparent z-40"
            ></button>
          )}

          {downloadButtonTwoActive && (
            <button
              onClick={() => setIsOpen(true)}
              className="h-[75%] absolute top-1/2 transform -translate-y-1/2 w-[60%] left-[7%] bg-transparent z-40"
            ></button>
          )}
        </div>

        <Button
          clickHandler={channelClickHandler}
          asset={channelKnob}
          rotation={channelRotation - 27.692307}
          className="z-30 absolute top-[20%] right-[10%] h-[15%] w-[12%]"
          imageClassName="w-full h-full object-contain"
          alt="Channel knob"
        />

        <div className="z-30 absolute top-[8%] right-[14.5%] flex-col flex w-[3.5%]">
          <Button
            clickHandler={topKnobClickHandler}
            asset={topKnob}
            rotation={topRotation}
            imageClassName="w-full h-full"
            alt="Volume knob"
          />
        </div>

        <div className="z-30 absolute top-[44%] right-[17%] w-[4%] flex flex-col justify-between h-[12.5%]">
          <Button
            clickHandler={(e) => sliderClickHandler(e)}
            data-index="1"
            index="1"
            asset={slider}
            style={
              sliderOneStatus === 'on'
                ? {
                    transform: 'translateX(1rem)',
                    transition: 'transform 0.3s ease',
                  }
                : {
                    transform: 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                  }
            }
            imageClassName="w-full h-full"
          />
          <Button
            clickHandler={(e) => sliderClickHandler(e)}
            data-index="2"
            index="2"
            asset={slider}
            style={
              sliderTwoStatus === 'on'
                ? {
                    transform: 'translateX(1rem)',
                    transition: 'transform 0.3s ease',
                  }
                : {
                    transform: 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                  }
            }
            imageClassName="w-full h-full"
          />

          <Button
            clickHandler={(e) => sliderClickHandler(e)}
            data-index="3"
            index="3"
            asset={slider}
            style={
              sliderThreeStatus === 'on'
                ? {
                    transform: 'translateX(1rem)',
                    transition: 'transform 0.3s ease',
                  }
                : {
                    transform: 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                  }
            }
            imageClassName="w-full h-full"
          />
        </div>

        <div className="bottom-[6%] right-[6%] w-[19%] h-[26%] absolute    z-30">
          <div className="relative h-full w-full">
            {grid.map((_, index) => (
              <button
                className="w-[14%] h-[15.5%] "
                onClick={handleKeyPadClick}
                data-key={index + 1}
                key={index}
              ></button>
            ))}
          </div>
        </div>
      </div>

      {isOpen && (
        <FocusTrap>
          <div className="fixed h-full w-full bg-ink bg-opacity-80 z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <label className="text-reverse font-lack">
                password required:
              </label>
              <input
                type="password"
                className="bg-reverse text-ink h-8 p-2"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="submit" className="bg-ink p-2 text-reverse">
                enter
              </button>
            </form>

            <button
              className="absolute font-lack top-8 right-8 text-reverse"
              onClick={closeDialog}
            >
              close
            </button>
          </div>
        </FocusTrap>
      )}

      <p className="absolute bottom-4 left-4 text-ink font-lack">
        website by
        <a href="https://www.youngandnauseo.us/" target="_blank">
          young and nauseous
        </a>
      </p>
    </div>
  );
}

export default LevelTwo;
